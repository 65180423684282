import {
  APP_INITIALIZER,
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, tap } from 'rxjs';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';
import { environment } from '@environments';

function loadSideKeyFactory(): () => Observable<{ siteKey: string }> {
  const http = inject(HttpClient);

  return () => {
    return http
      .get<{ siteKey: string }>(`${environment.apiHost}/recaptcha/site-key/`)
      .pipe(tap(({ siteKey }) => (environment.recaptchaSiteKey = siteKey)));
  };
}

export const provideRecaptcha = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    importProvidersFrom(RecaptchaV3Module),
    {
      provide: APP_INITIALIZER,
      useFactory: loadSideKeyFactory,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: () => environment.recaptchaSiteKey,
    },
  ]);
