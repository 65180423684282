import { HttpClient } from '@angular/common/http';
import {
  EnvironmentProviders,
  makeEnvironmentProviders,
  importProvidersFrom,
  inject,
} from '@angular/core';

import { environment } from '@environments';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

function HttpLoaderFactory(): TranslateHttpLoader {
  const http = inject(HttpClient);

  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const provideTranslate = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: environment.defaultLanguage,
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
        },
      }),
    ]),
  ]);
